import { Component, Mixins } from 'vue-property-decorator'
import UserService1 from '@/modules/users/UserService1'
import {ACCESS_CODE} from "@/plugins/Apollo";
import AboutService from "@/modules/about/AboutService";
import firebase from "firebase/compat";
import initializeApp = firebase.initializeApp;

class User {
  username: string = ''
  password: string = ''
}

@Component
export default class AuthenticationService extends Mixins(UserService1, AboutService) {
  user = new User()
  showError: string | null = null
  private KEY_NAME = 'Authorization'
  private MOBILE_HOME = 'all-plannings'
  private DESKTOP_HOME = 'planning-overview'

  rerender() {
    this.$store.state.showContent = false
    this.$nextTick(() => {
      this.$store.state.showContent = true
    })
  }

  get isMobile(): boolean {
    return window.innerWidth < 960
  }

  checkLogin(unauthorized: boolean, isMobilePage: boolean = false) {
    this.getAbout().then(result => this.$store.commit('setSystemSettings', result))

    let token = null
    let storage = null

    const ses = sessionStorage.getItem(this.KEY_NAME)
    if (ses) {
      token = ses
      storage = sessionStorage
    } else if(localStorage.getItem(this.KEY_NAME)) {
      const loc = localStorage.getItem(this.KEY_NAME)
      if (loc) {
        token = loc
        storage = localStorage
      }
    }

    if (token && storage) {
      this.$store.commit('setToken', { token, storage })
      this.$websocket.connect()
      this.setTitle()
      this.subscribeToPushNotifications()

      if (this.isMobile !== isMobilePage) {
        this.toHomePage()
      }
    } else {
      if (!unauthorized) {
        this.$router.push({ name: 'login' })
      }
    }
  }

  setTitle(notifications: number = 0) {
    // const name = this.$store.state.user && this.$store.state.user.company.name ? this.$store.state.user.company.name : 'Cargoledger'
    // const messageNumber = notifications ? ` (${notifications})` : ''
    // document.title = name + messageNumber
  }

  toHomePage() {
    this.$router.push({ name: this.isMobile ? this.MOBILE_HOME : this.DESKTOP_HOME })
  }

  login(rememberMe: boolean) {
    this.logout()
    this.showError = null

    this.authenticateUser(this.user.username, this.user.password)
      .then(user => {
        const token = user.LoginUser
        this.$store.commit('setToken', { token, storage: rememberMe ? localStorage : sessionStorage })
        this.toHomePage()
        this.subscribeToPushNotifications()
      })
      .catch((error) => {
        this.axios.post('/api/logout').then()
        console.log(error.toString())
        if (error.toString().includes("Invalid authentication")) {
          this.showError = 'errors.loginWrong'
        } else {
          this.showError = 'Difficulty reaching the server. You or the server may be offline. Please try again'
        }
      })
  }

  authenticateWithAccessToken() {
    this.getAbout().then(result => this.$store.commit('setSystemSettings', result))
    this.$websocket.connect()
  }

  logout() {
    if (this.$store.state.firebaseToken && this.$store.state.user) {
      this.deleteFirebaseToken(this.$store.state.firebaseToken).then()
    }
    localStorage.removeItem(this.KEY_NAME)
    sessionStorage.removeItem(this.KEY_NAME)
    localStorage.removeItem(ACCESS_CODE)
    this.$store.commit('clear')
    this.$store.commit('transport/clear')
    this.$store.commit('scan/clear')
    this.$router.push({ name: 'login' })
  }


  subscribeToPushNotifications() {
      // Your web app's Firebase configuration
      const firebaseConfig = {
        apiKey: "AIzaSyDMHXX99oX7yxS8ppU71zxorD8QiSfZOU4",
        authDomain: "cargoledger-6e2a3.firebaseapp.com",
        projectId: "cargoledger-6e2a3",
        storageBucket: "cargoledger-6e2a3.appspot.com",
        messagingSenderId: "780819408426",
        appId: "1:780819408426:web:ec4401e4278d2bf0a44553"
      };
      // Initialize Firebase
      const app = initializeApp(firebaseConfig)

      app.messaging().getToken({ vapidKey: 'BNqVV2zyr4j6NyJmKzQvY_abtBUBYDZsxm43dGsxOTlwpC2A8rqnsA5zAXfMEI4TutwlRZ6HWZDjuB7--sVvhmc' })
          .then((currentToken: string) => {
            if (currentToken) {
              this.$store.commit('setFirebaseToken', currentToken)
              this.updateFirebaseToken(currentToken).then()
              console.log('client token', currentToken)
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          }).catch((err: any) => {
        console.log('An error occurred while retrieving token. ', err);
      })

      app.messaging().onMessage(payload => {
        if (payload.data) {
          this.$store.commit('transport/setPlanningUpdated', Number(payload.data.id))
        }
      })
  }

  getHeaders() {
    return {
      [this.KEY_NAME]: this.$store.state.jwtToken
    }
  }
}
