import {Component, Vue} from 'vue-property-decorator'
import gql from 'graphql-tag'
import {ApolloQueryResult} from 'apollo-client'
import {GetAbout, GetAbout_GetAbout} from "@/generated/GetAbout";

@Component
export default class AboutService extends Vue {
  private getAboutQuery = gql`
    query GetAbout {
      GetAbout {
            key
            value
      }
    }
  `

  getAbout(): Promise<GetAbout_GetAbout[]> {
    return new Promise((resolve, reject) => {
      this.$apollo
        .query({
          query: this.getAboutQuery,
        })
        .then((result: ApolloQueryResult<GetAbout>) => {
          resolve(result.data.GetAbout)
        })
        .catch(reason => {
          reject(reason)
        })
    })
  }
}
